export const namespaced = true
import store from '@/store'
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        grade :'',
        grades : '',
        subjects:'',
        advert :'',
        adverts : '',
        topics: '',
        delivery_areas : '',
        trending_products : '',
        trending_lessons : '',
    },
    
    getters : {
        grade: (state )=> state.grade,
        grades: (state )=> state.grades,
        subjects: (state )=> state.subjects,
        advert: (state )=> state.advert,
        adverts: (state )=> state.adverts,
	    topics: (state )=> state.topics,
        delivery_areas: (state) => state.delivery_areas,
        trending_products: (state) => state.trending_products,
        trending_lessons: (state) => state.trending_lessons,
    },


    mutations : { 
        setgrade :(state,grade ) => (state.grade = grade), 
        setgrades :(state,grades ) => (state.grades = grades), 
        setsubjects :(state,subjects ) => (state.subjects = subjects), 
        setadvert :(state,advert ) => (state.advert = advert), 
        setadverts :(state,adverts ) => (state.adverts = adverts), 
        settopics :(state,topics ) => (state.topics = topics),
        setdelivery_areas :(state,delivery_areas ) => (state.delivery_areas = delivery_areas), 
        settrending_products :(state,trending_products ) => (state.trending_products = trending_products), 
        settrending_lessons :(state,trending_lessons ) => (state.trending_lessons = trending_lessons), 
    },

    actions : {

        async get_grades( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_grades')
            return new Promise((resolve, reject) => {
            axios.post("/teacher/global/grades?page=" + page, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                commit('setgrades', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_subjects( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_subjects')
            return new Promise((resolve, reject) => {
            axios.post("/teacher/global/subjects?page=" + page, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                commit('setsubjects', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_adverts( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_grades')
            return new Promise((resolve, reject) => {
            axios.post("/teacher/global/adverts?page=" + page, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                commit('setadverts', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_grade_subject_topics( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_grade_subject_topics')
            return new Promise((resolve, reject) => {
            axios.post("/teacher/global/topics?page=" + page, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                commit('settopics', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_delivery_areas( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_delivery_areas')
            return new Promise((resolve, reject) => {
            axios.post("/teacher/global/delivery_areas?page=" + page, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                commit('setdelivery_areas', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_trending_products( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_trending_products')
            return new Promise((resolve, reject) => {
            axios.post("/teacher/global/trending_products?page=" + page, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                commit('settrending_products', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_all_trending_lessons( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_all_trending_lessons')
            return new Promise((resolve, reject) => {
            axios.post("/teacher/global/trending_lessons?page=" + page, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                commit('settrending_lessons', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
    },

    
}













