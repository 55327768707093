export const namespaced = true
import store from '@/store'
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
  state: {
    assessment: "",
    assessments: "",
  },

  getters: {
    assessment: (state) => state.assessment,
    assessments: (state) => state.assessments,
  },

  mutations: {
    setassessment: (state, assessment) => (state.assessment = assessment),
    setassessments: (state, assessments) => (state.assessments = assessments),
  },

  actions: {
    async get_assessments({ commit }, { data, page }) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "get_assessments");
      return new Promise((resolve, reject) => {
        axios
          .post("/teacher/assessments?page=" + page, data)
          .then((response) => {
            commit("setloader", false);
            if (response.data) {
              commit("setassessments", response.data.res);
            }
            resolve(response);
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },

    async get_assessment_details({ commit }, { data, id }) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "get_assessment_details");
      return new Promise((resolve, reject) => {
        axios
          .post("/teacher/assessments/" + id, data)
          .then((response) => {
            commit("setloader", false);
            if (response.data) {
              commit("setassessment", response.data.res);
            }
            resolve(response);
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },

    async approve_assessment({ commit }, { data, id }) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "approve_assessment");
      return new Promise((resolve, reject) => {
        axios
          .post("/teacher/assessments/approve/" + id, data)
          .then((response) => {
            commit("setloader", false);
            if (response.data) {
              commit("setassessments", response.data.res);
            }
            resolve(response);
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },

    async start_assessment_meeting({ commit }, { data, id }) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "start_assessment_meeting");
      return new Promise((resolve, reject) => {
        axios
          .post("/teacher/assessments/start_meeting/" + id, data)
          .then((response) => {
            commit("setloader", false);
            if (response.data) {
              commit("setassessment", response.data.res);
            }
            resolve(response);
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },
  },
};













