export const namespaced = true;
import store from "@/store";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  state: {
    coaching: "",
    coachings: "",
  },

  getters: {
    coaching: (state) => state.coaching,
    coachings: (state) => state.coachings,
  },

  mutations: {
    setcoaching: (state, coaching) => (state.coaching = coaching),
    setcoachings: (state, coachings) => (state.coachings = coachings),
  },

  actions: {
    async get_coachings({ commit }, { data, page }) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "get_coachings");
      return new Promise((resolve, reject) => {
        axios
          .post("/teacher/coachings?page=" + page, data)
          .then((response) => {
            commit("setloader", false);
            if (response.data) {
              commit("setcoachings", response.data.res);
            }
            resolve(response);
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                } 
              }
            }
            reject(error);
          });
      });
    },

    async get_coaching_details({ commit }, { data, id }) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "get_coaching_details");
      return new Promise((resolve, reject) => {
        axios
          .post("/teacher/coachings/" + id, data)
          .then((response) => {
            commit("setloader", false);
            if (response.data) {
              commit("setcoaching", response.data.res);
            }
            resolve(response);
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },

    async approve_coaching({ commit }, { data, id }) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "approve_coaching");
      return new Promise((resolve, reject) => {
        axios
          .post("/teacher/coachings/approve/" + id, data)
          .then((response) => {
            commit("setloader", false);
            if (response.data) {
              commit("setcoachings", response.data.res);
            }
            resolve(response);
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
	  },
	

	async start_coaching_meeting({ commit }, { data, id }) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "start_coaching_meeting");
      return new Promise((resolve, reject) => {
        axios
          .post("/teacher/coachings/start_meeting/" + id, data)
          .then((response) => {
            commit("setloader", false);
            if (response.data) {
              commit("setcoaching", response.data.res);
            }
            resolve(response);
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },
  },
};
