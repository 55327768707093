export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        earnings :'', 
        earning :'', 
    },
    
    getters : {
        earnings: (state )=> state.earnings,
        earning: (state )=> state.earning,
    },


    mutations : { 
        setearnings :(state,earnings ) => (state.earnings = earnings), 
        setearning :(state,earning ) => (state.earning = earning), 
    },

    actions : {


        async get_earnings( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_earnings')
            return new Promise((resolve, reject) => {
            axios.post("/teacher/earnings?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setearnings', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async withdraw_earnings( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'withdraw_earnings' + id)
            return new Promise((resolve, reject) => {
            axios.post("/teacher/earnings/withdraw/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setearning', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async transfer_earnings( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'transfer_earnings' + id)
            return new Promise((resolve, reject) => {
            axios.post("/teacher/earnings/transfer/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setearnings', response.data.res)
                toast.success("Earnings Transferred Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
    }
}