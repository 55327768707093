export const namespaced = true
import store from '@/store'
import { useToast } from 'vue-toastification'
const toast = useToast()
export default {

    state : {
        lesson :'',
        lessons :'',
        lessons_cats :'',    
    },

    getters : {
        lesson: (state )=> state.lesson,
        lessons: (state )=> state.lessons,
        lessons_cats: (state )=> state.lessons_cats,
    },

    mutations : { 
        setlesson :(state,lesson ) => (state.lesson = lesson), 
        setlessons :(state,lessons ) => (state.lessons = lessons), 
        setlessons_cats :(state,lessons_cats ) => (state.lessons_cats = lessons_cats), 
    },

    actions :{
        async create_lesson( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_lesson')
            return new Promise((resolve, reject) => {
            axios.post("/teacher/lessons/create", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    commit('setlesson', response.data.res)
                    toast.success('Lesson Created Successfully')
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async update_lesson( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_lesson')
            return new Promise((resolve, reject) => {
            axios.post("/teacher/lessons/update/" + id, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    commit('setlesson', response.data.res)
                    toast.success('Lesson Updated Successfully')
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_lessons_cats( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_lessons_cats')
            return new Promise((resolve, reject) => {
            axios.post("/teacher/lessons?page=" + page, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                commit('setlessons_cats', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_lessons( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_lessons')
            return new Promise((resolve, reject) => {
            axios.post("/teacher/lessons?page=" + page, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                commit('setlessons', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_trending_lessons( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_lessons')
            return new Promise((resolve, reject) => {
            axios.post("/teacher/lessons/trending?page=" + page, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                commit('setlessons', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_lesson_details( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_lesson_details')
            return new Promise((resolve, reject) => {
            axios.post("/teacher/lessons/" + id, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                commit('setlesson', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },



        async update_lesson_image( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_lesson_image')
            return new Promise((resolve, reject) => {
            axios.post("/teacher/lessons/image/" + id, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    commit('setlesson', response.data.res)
                    toast.success('Lesson Image Updated Successfully')
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },



        //lesson sessions
        async create_lesson_session( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_lesson_session')
            return new Promise((resolve, reject) => {
            axios.post("/teacher/lessons/chapters/create", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                commit('setlesson', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async delete_lesson_session( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_lesson_session'+id)
            return new Promise((resolve, reject) => {
            axios.post("/teacher/lessons/chapters/delete/"+id, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                commit('setlesson', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },



        //less sessions files
        async create_lesson_session_file( { commit }, data, ) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_lesson_session_file')
            return new Promise((resolve, reject) => {
            axios.post("/teacher/lessons/chapters/files/create", data, {
                onUploadProgress: function( progressEvent ) {
                  var newuploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) );
                  commit('setuploadPercentage', newuploadPercentage )
                }.bind(this)
               
              },)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setlesson', response.data.res)
                commit('setuploadPercentage', 0 )
                toast.success("Lesson Session Added Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            commit('setuploadPercentage', 0 )
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async delete_lesson_session_file( { commit }, {data, id}, ) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_lesson_session_file')
            return new Promise((resolve, reject) => {
            axios.post("/teacher/lessons/chapters/files/delete/"+id, data, {
                // onUploadProgress: function( progressEvent ) {
                //   var newuploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) );
                //   commit('setuploadPercentage', newuploadPercentage )
                // }.bind(this)
               
              },)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setlesson', response.data.res)
                commit('setuploadPercentage', 0 )
                toast.success("Lesson Session deleted successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            commit('setuploadPercentage', 0 )
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
        

        //lesson tests
        async create_lesson_test( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_lesson_test')
            return new Promise((resolve, reject) => {
            axios.post("/teacher/lessons/tests/create", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                commit('setlesson', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async delete_lesson_test( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_lesson_test')
            return new Promise((resolve, reject) => {
            axios.post("/teacher/lessons/tests/delete/"+id, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                commit('setlesson', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },



        //less chapters files
        async create_lesson_test_qn( { commit }, data, ) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_lesson_test_qn')
            return new Promise((resolve, reject) => {
            axios.post("/teacher/lessons/tests/qns/create", data, {
                onUploadProgress: function( progressEvent ) {
                  var newuploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) );
                  commit('setuploadPercentage', newuploadPercentage )
                }.bind(this)
               
              },)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setlesson', response.data.res)
                commit('setuploadPercentage', 0 )
                toast.success("New record added successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            commit('setuploadPercentage', 0 )
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
        
        
        async delete_lesson_test_qn( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_lesson_test_qn')
            return new Promise((resolve, reject) => {
            axios.post("/teacher/lessons/tests/qns/delete/"+id, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                commit('setlesson', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
    }


}