import store from '@/store'

const adminguard = (to, from, next) => {
  const user = store.getters.authuser;
  if(user && user.role == 'teacher' && localStorage.getItem('educanteachertoken'+ user.id) != null && user.gradecurriculum_id != null && user.phone_verified_at != null ){
      return next()
  }else if(user && user.role != 'student'){
      return next({ name: 'errorpage' })
  }else{
      return next({ name: 'login' })
  }
};


let routes = [
  {
    path: "/",
    props: true,
    beforeEnter: adminguard,
    component: () => import("@/views/app/main.vue"),
    title: "",

    children: [
      {
        path: "/",
        name: "welcome",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/dash.vue"),
        meta: {
          title: "Welcome to your Dashboard",
        },
      },

      {
        path: "/lessons",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/lessons/main.vue"),
        meta: {
          title: "Find Lessons for You",
        },

        children: [
          {
            path: "",
            name: "lessons",
            props: true,
            beforeEnter: adminguard,
            component: () => import("@/views/app/lessons/lessons.vue"),
            meta: {
              title: "Create a Lesson",
            },
          },

          {
            path: "create-lesson",
            name: "create_lesson",
            props: true,
            beforeEnter: adminguard,
            component: () => import("@/views/app/lessons/create_lesson.vue"),
            meta: {
              title: "Create a Lesson",
            },
          },

          {
            path: "details",
            props: true,
            beforeEnter: adminguard,
            component: () => import("@/views/app/lessons/manage/main.vue"),
            children: [
              {
                path: ":id",
                name: "lesson_details",
                props: true,
                beforeEnter: adminguard,
                component: () =>
                  import("@/views/app/lessons/manage/lesson_details.vue"),
                meta: {
                  title: "Manage Lesson",
                },
              },

              {
                path: "chapters/:id",
                name: "lesson_chapters",
                props: true,
                beforeEnter: adminguard,
                component: () =>
                  import("@/views/app/lessons/manage/lesson_chapters.vue"),
                meta: {
                  title: "Manage Lesson Chapters",
                },
              },

              {
                path: "tests/:id",
                name: "lesson_tests",
                props: true,
                beforeEnter: adminguard,
                component: () =>
                  import("@/views/app/lessons/manage/lesson_tests.vue"),
                meta: {
                  title: "Manage Lesson Chapters",
                },
              },

              {
                path: "reviews/:id",
                name: "lesson_reviews",
                props: true,
                beforeEnter: adminguard,
                component: () =>
                  import("@/views/app/lessons/manage/lesson_reviews.vue"),
                meta: {
                  title: "Manage Lesson Chapters",
                },
              },
              {
                path: "update/:id",
                name: "update_lesson",
                props: true,
                beforeEnter: adminguard,
                component: () =>
                  import("@/views/app/lessons/manage/update_lesson.vue"),
                meta: {
                  title: "Manage Lesson Chapters",
                },
              },
            ],
          },
        ],
      },

      {
        path: "/tests",
        name: "tests",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/tests/tests.vue"),
        meta: {
          title: "Find Lessons for You",
        },
      },

      {
        path: "/edushop",
        name: "",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/shop/main.vue"),
        children: [
          {
            path: "",
            name: "edushop",
            props: true,
            beforeEnter: adminguard,
            component: () => import("@/views/app/shop/products.vue"),
            meta: {
              title: "Find What You need at Edushop",
            },
          },

          {
            path: "/edushop/:id",
            name: "product_details",
            props: true,
            beforeEnter: adminguard,
            component: () => import("@/views/app/shop/productdetails.vue"),
            meta: {
              title: "Find What You need at Edushop",
            },
          },
        ],
      },

      {
        path: "/resources",
        name: "",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/resources/main.vue"),
        children: [
          {
            path: "",
            name: "resources",
            props: true,
            beforeEnter: adminguard,
            component: () => import("@/views/app/resources/resources.vue"),
            meta: {
              title: "Find What You need at resources",
            },
          },

          {
            path: "/resources/:id",
            name: "resource_details",
            props: true,
            beforeEnter: adminguard,
            component: () => import("@/views/app/resources/resource_details.vue"),
            meta: {
              title: "Find What You need at resources",
            },
          },
        ],
      },

      {
        path: "/past-papers",
        name: "pastpapers",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/pastpapers/pastpapers.vue"),
        meta: {
          title: "Find Lessons for You",
        },
      },

      {
        path: "/coaching",
        name: "coachings",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/coachings/coachings.vue"),
        meta: {
          title: "Find Lessons for You",
        },
      },

      {
        path: "/coachings",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/coachings/main.vue"),
        children: [
          {
            path: "",
            name: "coachings",
            props: true,
            beforeEnter: adminguard,
            component: () => import("@/views/app/coachings/coachings.vue"),
            meta: {
              title: "Find Lessons for You",
            },
          },
          {
            path: ":id",
            name: "coaching_details",
            props: true,
            beforeEnter: adminguard,
            component: () =>
              import("@/views/app/coachings/coaching_details.vue"),
            meta: {
              title: "Find Lessons for You",
            },
          },
        ],
      },


      {
        path: "/consultations",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/consultations/main.vue"),
        children: [
          {
            path: "",
            name: "new_consultations",
            props: true,
            beforeEnter: adminguard,
            component: () => import("@/views/app/consultations/consultations.vue"),
            meta: {
              title: "Manage Consultations Forwarded",
            },
          },

        ],
      },

      {
        path: "/assessments",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/assessments/main.vue"),
        children: [
          {
            path: "",
            name: "assessments",
            props: true,
            beforeEnter: adminguard,
            component: () => import("@/views/app/assessments/assessments.vue"),
            meta: {
              title: "Find Lessons for You",
            },
          },
          {
            path: ":id",
            name: "assessment_details",
            props: true,
            beforeEnter: adminguard,
            component: () =>
              import("@/views/app/assessments/assessment_details.vue"),
            meta: {
              title: "Find Lessons for You",
            },
          },
        ],
      },

      {
        path: "/chatroom",
        name: "chatroom",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/chats/chats.vue"),
        meta: {
          title: "Find Lessons for You",
        },
      },


      {
              path: "/shopping_cart",
              name: "",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/cart/main.vue"),
              children: [
                {
                  path: "cart",
                  name: "cart",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/cart/cart.vue"),
                  meta: {
                  title: 'Find What You need at Edushop'
                  }
                },


                {
                  path: "checkout",
                  name: "checkout",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/cart/checkout.vue"),
                  meta: {
                  title: 'Find What You need at Edushop'
                  }
                },

              ]
            },

      {
        path: "/settings",
        name: "",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/myaccount/main.vue"),
        children: [
          {
            path: "account",
            name: "myaccount",
            props: true,
            beforeEnter: adminguard,
            component: () => import("@/views/app/myaccount/myaccount.vue"),
            meta: {
              title: "Find What You need at Edushop",
            },
          },

          {
            path: "my-wallet",
            name: "mywallet",
            props: true,
            beforeEnter: adminguard,
            component: () => import("@/views/app/myaccount/mywallet.vue"),
            meta: {
              title: "Find What You need at Edushop",
            },
          },

          {
            path: "my-earnings",
            name: "myearnings",
            props: true,
            beforeEnter: adminguard,
            component: () => import("@/views/app/myaccount/earnings.vue"),
            meta: {
              title: "Find What You need at Edushop",
            },
          },

          {
            path: "access-history",
            name: "accesshistory",
            props: true,
            beforeEnter: adminguard,
            component: () => import("@/views/app/myaccount/accesshistory.vue"),
            meta: {
              title: "Find What You need at Edushop",
            },
          },

          {
            path: "get-help",
            name: "gethelp",
            props: true,
            beforeEnter: adminguard,
            component: () => import("@/views/app/myaccount/gethelp.vue"),
            meta: {
              title: "Find What You need at Edushop",
            },
          },
        ],
      },


      {
        path: "/notifications",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/notifications/main.vue"),
        children: [
          {
            path: "",
            name: "notifications",
            props: true,
            beforeEnter: adminguard,
            component: () => import("@/views/app/notifications/notifications.vue"),
            meta: {
              title: "Find Lessons for You",
            },
          },
        ],
      },


      {
              path: "/impacts",
              props: true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/impacts/main.vue"),
              children: [
                {
                  path: "",
                  name: "impacts",
                  props: true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/impacts/impacts.vue"),
                  meta: {
                    title: "Find Lessons for You",
                  },
                },
              ],
            },



            {
              path: "/scholarships",
              props: true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/scholarships/main.vue"),
              children: [
                {
                  path: "",
                  name: "scholarships",
                  props: true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/scholarships/scholarships.vue"),
                  meta: {
                    title: "Find Lessons for You",
                  },
                },
              ],
            },
    ],
  },

  {
    path: "/error",
    name: "errorpage",
    props: true,
    component: () => import("@/views/noaccess.vue"),
    meta: {
      title: "Your Not Allowed to Access the Page you tried to Access",
    },
  },

  {
    path: "/thank-you",
    name: "thankyou",
    props: true,
    component: () => import("@/views/thankyou.vue"),
    meta: {
      title: "We will get back to you Shortly",
    },
  },
];
export default routes;