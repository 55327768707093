export const namespaced = true;
import store from "@/store";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  state: {
    consultation: "",
    consultations: "",
  },

  getters: {
    consultation: (state) => state.consultation,
    consultations: (state) => state.consultations,
  },

  mutations: {
    setconsultation: (state, consultation) => (state.consultation = consultation),
    setconsultations: (state, consultations) => (state.consultations = consultations),
  },

  actions: {
    async get_consultations({ commit }, { data, page }) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "get_consultations");
      return new Promise((resolve, reject) => {
        axios
          .post("/teacher/consultations?page=" + page, data)
          .then((response) => {
            commit("setloader", false);
            if (response.data) {
              commit("setconsultations", response.data.res);
            }
            resolve(response);
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                } 
              }
            }
            reject(error);
          });
      });
    },

    async get_consultation_details({ commit }, { data, id }) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "get_consultation_details");
      return new Promise((resolve, reject) => {
        axios
          .post("/teacher/consultations/" + id, data)
          .then((response) => {
            commit("setloader", false);
            if (response.data) {
              commit("setconsultation", response.data.res);
            }
            resolve(response);
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },

    async approve_consultation({ commit }, { data, id }) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "approve_consultation");
      return new Promise((resolve, reject) => {
        axios
          .post("/teacher/consultations/approve/" + id, data)
          .then((response) => {
            commit("setloader", false);
            if (response.data) {
              commit("setconsultations", response.data.res);
            }
            resolve(response);
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
	  },
	

	async start_consultation_meeting({ commit }, { data, id }) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "start_consultation_meeting");
      return new Promise((resolve, reject) => {
        axios
          .post("/teacher/consultations/start_meeting/" + id, data)
          .then((response) => {
            commit("setloader", false);
            if (response.data) {
              commit("setconsultation", response.data.res);
            }
            resolve(response);
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },
  },
};
