<template>
      <router-view ></router-view>
      <paymentmodel ref="paymentmodel"></paymentmodel>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import paymentmodel from '@/views/comps/paymentmodel.vue';
export default{
    components: {
        paymentmodel,
    },
    methods:{
        ...mapActions(['getuserlocation','getuseripaddress'])
    },

    created(){
        this.getuseripaddress().then(()=>{
            this.getuserlocation();
        })
    }
}
</script>

