import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import SecureLs from 'secure-ls'
const secure = new SecureLs({isCompression:false});
import auth  from './modules/auth'
import utils  from './modules/utils'
import essentials  from './modules/essentials'
import teacher_resources  from './modules/teacher_resources'
import lessons  from './modules/lessons'
import assessments  from './modules/assessments'
import coachings from "./modules/coachings";
import products from "./modules/products";
import library from "./modules/library";
import papers from "./modules/papers";
import cart from "./modules/cart";
import chat from "./modules/chat";
import orders from "./modules/orders";
import wallet from "./modules/wallet";
import notifications from "./modules/notifications";
import impacts from "./modules/impacts";
import scholarships from "./modules/scholarships";
import earnings from "./modules/earnings";
import feedback from "./modules/feedback";
import consultations from "./modules/consultations";
export default createStore({
  modules: {
    auth,
    utils,
    essentials,
    teacher_resources,
    lessons,
    assessments,
    coachings,
    products,
    library,
    papers,
    cart,
    chat,
    orders,
    wallet,
    notifications,
    impacts,
    scholarships,
    earnings,
    feedback,
    consultations,
  },

  plugins: [
    createPersistedState({
      key: "educanug.com",
      paths: ["auth", "utils"],
      storage: {
        getItem: (key) => secure.get(key),
        setItem: (key, value) => secure.set(key, value),
        removeItem: (key) => secure.remove(key),
      },
    }),
  ],
});
