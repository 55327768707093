// The code snippet defines a route configuration for an authentication system. It includes routes for login, registration, password recovery, profile completion, and account verification. The "authguard" function is used as a beforeEnter guard to check if the user meets certain
import store from '@/store'
const authguard = (to, from, next) => {
  const user = store.getters.authuser;

  if (user && user.role == 'teacher' && localStorage.getItem('educanteachertoken'+ user.id) != null && user.gradecurriculum_id != null && user.phone_verified_at != null) {
    return next({ name: 'welcome' });
  } else if (user && user.gradecurriculum_id == null) {
    return next({ name: 'complete_profile' });
  } else if (user && user.phone_verified_at == null) {
    return next({ name: 'request_verify_account' });
  } else {
    return next();
  }
};

let routes = [
    {
        path: "/auth/",
        props:true,
       
        component: () => import("@/views/auth/main.vue"),


        children: [
          {
            path: "login",
            name: "login",
            props:true,
            beforeEnter: authguard,
            component: () => import("@/views/auth/login.vue"),
            meta: {
             title: 'Login to Your Dashboard'
            }
          },
          {
            path: "forgot-password",
            name: "forgotpassword",
            props:true,
            beforeEnter: authguard,
            component: () => import("@/views/auth/fogotpassword.vue"),
            meta: {
             title: 'Create an  Account'
            }
          },

          {
            path: "complete-profile",
            name: "complete_profile",
            props:true,
           
            component: () => import("@/views/auth/complete_profile.vue"),
            meta: {
             title: 'Create an  Account'
            }
          },

          {
            path: "request-verify-account",
            name: "request_verify_account",
            props:true,
            component: () => import("@/views/auth/request_verify_account.vue"),
            meta: {
             title: 'Request Verification Code'
            }
          },

          {
            path: "verify-account",
            name: "verify_account",
            props:true,
            component: () => import("@/views/auth/verify_account.vue"),
            meta: {
             title: 'Verify Account'
            }
          },
        ]
    },

    {
      path: "/create-account",
      name: "register",
      props:true,
      beforeEnter: authguard,
      component: () => import("@/views/auth/register.vue"),
      meta: {
       title: 'Create an  Account'
      }
    },

]
export default routes;