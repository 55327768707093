export const namespaced = true
import store from '@/store'
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        libraryitems:'',
        libraryitem:'',
        
    },
    
    getters : {
	    libraryitems: (state )=> state.libraryitems,
	    libraryitem: (state )=> state.libraryitem,
       
    },


    mutations : {
        setlibraryitems :(state,libraryitems ) => (state.libraryitems = libraryitems), 
        setlibraryitem :(state,libraryitem ) => (state.libraryitem = libraryitem), 
      
    },

    actions : {

        async get_libraryitems( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_libraryitems')
            return new Promise((resolve, reject) => {
            axios.post("/teacher/global/library?page=" + page, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                commit('setlibraryitems', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_libraryitem( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_libraryitem')
            return new Promise((resolve, reject) => {
            axios.post("/teacher/global/library/" + id, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                commit('setlibraryitem', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
    },   
}