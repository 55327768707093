export const namespaced = true
import store from '@/store'
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        wallet :'', 
    },
    
    getters : {
        wallet: (state )=> state.wallet,
    },


    mutations : { 
        setwallet :(state,wallet ) => (state.wallet = wallet), 
    },

    actions : {

        async get_user_wallet( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_user_wallet')
            return new Promise((resolve, reject) => {
            axios.post("/user/wallet", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                commit('setwallet', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },



        async deposit_cash( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'deposit_cash')
            return new Promise((resolve, reject) => {
            axios.post("/user/deposit/"+id, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                commit('setwallet', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },




        async withdraw_cash( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'withdraw_cash')
            return new Promise((resolve, reject) => {
            axios.post("/user/withdraw/"+id, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                commit('setwallet', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },



    },

    
}













